<template>
  <div class="order-query">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ title }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <van-tabs @click="onSearch">
        <van-tab :title="$t('已签收')" name="finish"> </van-tab>
        <van-tab :title="$t('全部订单')" name="all"> </van-tab>
        <van-tab :title="$t('已取消')" name="cancel"> </van-tab>
        <van-tab :title="$t('问题单')" name="issue"> </van-tab>
        <van-tab :title="$t('退货单')" name="mrb"> </van-tab>
      </van-tabs>
      <div class="flexbox">
        <van-search
          class="flex-fill"
          v-model="keyword"
          :placeholder="$t('请输入搜索关键词')"
          @search="onSearch"
        />
      </div>
      <section v-if="list.length > 0">
        <section class="list padding" :style="[bodyHeight]">
          <div class="card" v-for="(item, index) in list" :key="index">
            <template v-if="$route.query.type === 'order'">
              <header class="flexbox space-bt card-header">
                <div>{{$t('订单号')}}：{{ item.sOrderNo | empty }}</div> 
                <div class="theme-color">{{ item.StatusDesc }}</div>
              </header>
              <div
                class="card-body flexbox space-bt"
                @click="
                  $router.push({
                    name: 'OrderDetail',
                    query: { orderno: item.sOrderNo },
                  })
                "
              >
                <div>
                  <div>
                   {{ $t('唛头')}}：<span class="theme-color">{{ item.sShipMark }}</span>
                  </div>
                  <div>
                    {{$t('收件人')}}：<span class="theme-color">{{ item.sRecName }}</span>
                  </div>
                  <div>
                    <span>
                      {{$t('收货电话')}}：<span class="theme-color">
                        {{ item.sRecTel }}</span
                      ></span
                    >
                  </div>
                  <div>
                    {{$t('收件地址')}}：{{
                      (item.sCnCountryName == null ? '' : item.sCnCountryName) +
                        ' ' +
                        (item.sRecCity == null ? '' : item.sRecCity) +
                        ' ' +
                        (item.sAddr1 == null ? '' : item.sAddr1) +
                        ' ' +
                        (item.sAddr2 == null ? '' : item.sAddr2)
                    }}
                  </div>
                  <div>{{$t('物品名称')}}：{{ item.goods_name | empty }}</div>
                  <div>{{$t('备注')}}：{{ item.remark | empty }}</div>
                  <div>{{$t('货值')}}：{{ item.fDeclareValue | empty }}</div>
                  <div>
                    {{$t('运输方式')}}:
                    <span class="round-label"> {{ item.transType }}</span>
                  </div>
                  <div>  {{$t('单据日期')}}: {{ item.createtime | time }}</div>
                  <div class="flexbox">
                    <div v-if="userInfo.bWatchVol == true && item.totalVol > 0">
                      {{$t('体积')}}：<span class="order-info-item">{{
                        item.totalVol | empty
                      }}</span>
                    </div>
                    <div
                      v-if="userInfo.bWatchVol == true && item.totalWeight > 0"
                    >
                      {{$t('重量')}}：<span class="order-info-item">{{
                        item.totalWeight | empty
                      }}</span>
                    </div>
                    <div>
                      {{$t('箱数')}}：<span class="order-info-item">{{
                        item.totalBags | empty
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <span class="pull-left"
                  >{{$t('目的地')}}：<span class="round-label">
                    {{ item.targetPlace }}</span
                  >
                </span>
                <!-- <a class="round-label" :href="'/tracks?key=' + item.sOrderNo"
                >轨迹详情</a
              > -->
              </footer>
            </template>
          </div>
        </section>
        <div class="pagination">
          <van-pagination
            @change="onSearch"
            v-model="currentPage"
            :total-items="totalCount"
            :items-per-page="limit"
            :show-page-size="4"
            force-ellipses
          />
        </div>
      </section>
      <section v-else class="padding no-item">
        <div class=""><img :src="emptyImg" /></div>
        <div class="nodesc">暂时没有记录</div>
      </section>
    </main>
  </div>
</template>
<script>
import { getOrderList } from '@/api/deliver.js';
import { BASE_TMS_URL } from '@/api/index.js';
import { ImagePreview } from 'vant';

export default {
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      list: [],
      emptyImg: require('../../assets/images/noinfo.png'),
      userInfo: this.$store.getters.userInfo,
      tms_img_url: BASE_TMS_URL,
      totalCount: 0,
      currentPage: 1,
      limit: 15,
      state: -100,
      bodyHeight: { height: '644px', overflow: 'auto' },
    };
  },
  computed: {
    title() {
      return '订单查询';
    },
  },
  mounted() {
    let hei = window.innerHeight - 230;
    this.bodyHeight.height = hei + 'px';
    this.state = -20;
    this.onSearch();
  },
  methods: {
    onSearch(name) {
      if (name == 'finish') {
        this.state = -20; //已经签收
      } else if (name == 'all') {
        this.state = -2; //全部订单查询
      } else if (name == 'mrb') {
        this.state = -11; //退货单
      } else if (name == 'issue') {
        this.state = -3; //问题单
      } else if (name == 'cancel') {
        this.state = -12; //取消单子
      }

      let offcount = (this.currentPage - 1) * this.limit;
      getOrderList({
        keyword: this.keyword,
        state: this.state,
        dabao: -2,
        measure: -2,
        limit: this.limit,
        offset: offcount,
      }).then(res => {
        this.list = res.rows;
        this.totalCount = res.total;
      });
    },
    onImgPreview(imgurl) {
      if (imgurl == '' || imgurl == null) {
        return;
      }
      ImagePreview([imgurl]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.card {
  padding: rem(20px);
  padding-top: 0;
  border-radius: rem(15px);
  box-shadow: 0px 2px 10px 0px rgba(14, 48, 53, 0.1);
  background-color: #fff;
  .card-header {
    color: #b8b8b8;
    font-size: 12px;
    border-bottom: 1px solid #efefef;
    height: rem(53px);
    line-height: rem(53px);
  }
  .card-body {
    padding: rem(20px) 0;
    border-bottom: 1px solid #efefef;
    line-height: rem(52px);
  }
  .card-footer {
    color: #666;
    padding-top: rem(20px);
    height: rem(30px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  .foot-desc {
    font-size: rem(26px);
    border-color: #b9aebb;
    border: 1px solid;
    color: #b9aebb;
    padding: 0 10px;
    border-radius: 4px;
    margin-right: 0.26rem;
  }
}
.padding {
  padding: rem(20px);
}
.img {
  width: rem(280px);
  height: rem(150px);
}
.pagination {
  margin: rem(10px);
}
.van-pagination__item {
  color: #b9aebb;
}
.van-pagination__item
  .van-pagination__item--active
  .van-pagination__page
  .van-hairline {
  color: #fff;
  background-color: #b9aebb;
}
.noimg {
  width: auto;
  height: rem(150px);
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(249px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}

.round-label {
  background: #b9aebb;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  margin: 0 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.order-info-item {
  border-color: #b9aebb;
  border: 1px solid;
  color: #b9aebb;
  padding: 0px 10px;
  border-radius: 4px;
  margin-right: rem(20px);
}
</style>
