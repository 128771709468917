const qs = require('querystring');
import API from './index';

export function addPackage({ name, deliveryNo, remarks, declarePrice }) {
  // goods-name=包包&delivery-no=SF23302214558&pacakge-remark=
  return API.post(
    '/package/AddPackage',
    qs.stringify({
      'goods-name': name,
      'delivery-no': deliveryNo,
      'pacakge-remark': remarks,
      'declare-price': declarePrice,
    })
  ).then(res => res.data);
}

//查询仓库信息
export function getStockInfo() {
  return API.get('/firm/getstockinfo').then(res => res.data);
}

//查询基础数据配置表
export function getCommonItemList(code) {
  return API.get('/common/GetItems?code=' + code).then(res => res.data);
}

// order=asc&offset=0&limit=15&state=-2&start-date=2020-06-21&end-date=2020-12-21&input-search=&haspacked=false
// 其中haspacked表示是否已经打包,也可以没有这个参数,包含参数,不包含参数,包含参数有 是否,两种状态,都有含义
export function getPackageList({
  keyword,
  packed,
  state, //.-1待入库的，0已入库1已发货2待认领，3问题包
  startDate,
  endDate,
  order,
  limit,
  offset,
}) {
  return API.get('/Package/QueryPackage', {
    params: {
      'input-search': keyword,
      'start-date': startDate,
      'end-date': endDate,
      'sel-measure': -2,
      state,
      haspacked: packed,
      order,
      limit,
      offset,
    },
  }).then(res => res.data);
}

export function getOrderList({
  keyword,
  state, //.-1待入库的，0已入库1已发货2待认领，3问题包
  measure,//默认-2
  dabao,//默认：-2
  startDate,
  endDate,
  order,
  limit,
  offset,
}) {
  return API.get('/Package/QueryPackageOrder', {
    params: {
      'input-search': keyword,
      'start-date': startDate,
      'end-date': endDate,
      'sel-measure': measure,
      'sel-dabao': dabao,
      state,
      order,
      limit,
      offset,
    },
  }).then(res => res.data);
}

export function getTracks({ orderNo } = {}) {
  return API.get('/track/Query', {
    params: {
      orderno: orderNo,
    },
  }).then(res => res.data);
}

export function getStatsData() {
  return API.post('/package/GetNumOfCount').then(res => res.data);
}

// sel-targetplace=1016&transport-type=3&txt-sender=%E9%B9%B3%E7%8B%B8%E7%8C%BF
// &txt-sendertel=15012926608&txt-prod=&ids=1063%2C&prd-type=1020&order-remark=%E5%B0%8F%E5%BF%83%E8%BD%BB%E6%94%BE&hd-receiverid=15

// 其中:hd-receiverid是收件人ID,
// ids是包裹列表明细ID,用小逗号分隔,产品类型用,运输方式,3.,4,5通过一个接口获取,commtype;sel-targetplace 是目的地,也是一个基本类型列表里面的,通过接口获取,txt-sender发送人,txt-sendertel 发送人电话
export function submitOrder({
  destination,
  transportType,
  sender,
  senderTel,
  name,
  type,
  remark,
  receiverId,
  lineId,
  packageIds = [],
} = {}) {
  return API.post(
    '/Package/PlacePackageOrder',
    qs.stringify({
      'sel-targetplace': destination,
      'sel-line': lineId,
      'transport-type': transportType,
      'txt-sender': sender,
      'txt-sendertel': senderTel,
      'txt-prod': name,
      'prd-type': type,
      'order-remark': remark,
      'hd-receiverid': receiverId,
      ids: packageIds.join(','),
    })
  ).then(res => res.data);
}

export function getCountryList() {
  return API.get('/common/getcountrylist').then(res => res.data);
}

export function getOrderInfo(orderno) {
  return API.get('/package/GetOrderByNo?orderno=' + orderno).then(
    res => res.data
  );
}

export function GetOrderPackageListByNo(orderno) {
  return API.get('/package/GetOrderPackageListByNo?orderno=' + orderno).then(
    res => res.data
  );
}

//查询专线
export function getLineList() {
  return API.get('/Package/GetLineList').then(res => res.data);
}

//修改包裹的商品名称和货值
export function editPkgGoods({
  id,
  amount,
  gname,
  note,
}) {
  return API.post('/Package/EditOrderGoods', qs.stringify({

    'pkgid': id,
    'txt-hz': amount,
    'txt-goodname': gname,
    'txt-note': note,
  }
  )).then(res => res.data);
}


//查询包裹信息
export function getPackageInfoByID(id) {
  return API.get('/Package/GetPackageInfoByID?id=' + id).then(res => res.data);
}
